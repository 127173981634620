<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-2xl font-bold mr-auto">產品管理</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div
          v-show="!grid.isEditing && grid.isRowSelected"
          id="batch-dropdown"
          class="dropdown ml-auto mr-2 sm:ml-0"
        >
          <button class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center">
            <FontAwesome
              icon="tasks"
              type="fas"
              class="w-4 h-4 mr-1"
            />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" /> 刪除
              </span> -->
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span> -->
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'csv', mode: 'selected' })"
              >
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" />
                匯出 CSV
              </span> -->
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span> -->
            </div>
          </div>
        </div>
        <button
          v-show="!grid.isEditing"
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click.prevent="grid.addNewRow"
        >
          <FontAwesome
            icon="plus"
            type="fas"
            class="w-4 h-4 mr-1"
          />
          新增
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <select
              v-model="selectedBrand"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border mr-2"
            >
              <option value="">選擇品牌</option>
              <option
                v-for="(item, index) in country"
                :key="'country-' + index"
                :value="item.Id"
                class="py-3"
              >
                {{ item.Name }}
              </option>
            </select>
          </div>
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <select
              v-model="selectedPiece"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border mr-2"
            >
              <option value="">選擇片數</option>
              <option value="0-300">300片以下</option>
              <option value="301-500">301-500片</option>
              <option value="501-1000">501-1000片</option>
              <option value="1001-1500">1001-1500片</option>
              <option value="1501-999999">1500片以上</option>
            </select>
          </div>
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字"
              @keyup.enter="grid.refresh"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div>
        </div>
        <div class="flex mt-2 sm:mt-0">
          <!-- <button
            class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            @click="grid.onPrint({ mode: 'curret' })"
          >
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button> -->
          <!-- <div class="dropdown w-1/2 sm:w-auto ml-2">
            <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a> -->
          <!-- <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a> -->
          <!-- </div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <Grid
          ref="grid"
          v-bind="gridOptions"
          @addNewRow="onGridAdd"
          @edit="onGridEdit"
          @removeSelectedRows="onGridRemoveSelectedRows"
        >
          <!-- <template #operate="{ row }">
            <slot name="operate" :row="row">
              <div class="flex" v-if="!isEditing">
                <button
                  class="button rounded-full mr-1 mb-2 bg-theme-1 text-white"
                  title="編輯"
                  @click="edit(row)"
                >
                  <FontAwesome icon="highlighter" class="w-4 h-4" />
                </button>
                <button
                  class="button rounded-full mr-1 mb-2 bg-theme-6 text-white"
                  title="取消"
                  @click="remove(row)"
                >
                  <FontAwesome icon="trash" class="w-4 h-4" />
                </button>
              </div>
            </slot>
          </template> -->

          <template #modal="{ row, submit, reset }">
            <div class="flex items-center">
              <FileUploader
                ref="uploader"
                class="mx-5"
                id="product-photo-uri"
                style="min-width: 150px; max-width: 150px; min-height: 150px; max-height: 180px;"
                mode="image"
                :modelValue="row.Photo?.Uri"
                :action="uploadAction"
                :autoUpload="true"
                :limitedWidth="294"
                :limitedHeight="294"
                :headers="{ Authorization: 'Basic ' + $model.getCertificate(0) }"
                @update:modelValue="(value) => { if (row.Photo) row.Photo.Uri = value; else row.Photo = { Uri: value }; }"
                @filter="uploaderFilter"
              />
              <vxe-form
                ref="form"
                class="w-full"
                v-bind="formOptions"
                :data="row"
                @submit="submit"
                @reset="reset"
              >
                <template #column-category-id="{ data }">
                  <SelectBox
                    :transfer="true"
                    v-model="data.CategoryId"
                    v-bind="categorySelectOptions"
                  />
                </template>
                <template #column-brand-id="{ data }">
                  <SelectBox
                    :transfer="true"
                    v-model="data.BrandId"
                    v-bind="brandSelectOptions"
                    @change="onFormBrandChanged(data, $event)"
                  />
                </template>
                <template #column-country-id="{ data }">
                  <SelectBox
                    :transfer="true"
                    v-model="data.CountryId"
                    v-bind="countrySelectOptions"
                  />
                </template>
              </vxe-form>
            </div>
            <div class="mt-2 ml-3 flex items-center">
              <span class="w-8 mr-3">標籤</span>
              <div class="w-full mr-3">
                <SelectBox
                  :transfer="true"
                  v-model="row.TagIds"
                  v-bind="tagsSelectOptions"
                />
              </div>
            </div>
            <div class="post intro-y overflow-hidden box mx-3 mt-2 border p-3">
              <div class="flex items-center ml-1 mb-3">
                <a
                  href="#"
                  @click.prevent="showAttribute = !showAttribute"
                >屬性</a>
                <a
                  href="#"
                  @click.prevent="showAttribute = !showAttribute"
                >
                  <FontAwesome
                    class="ml-2 h-4"
                    :icon="showAttribute ? 'angle-up' : 'angle-down'"
                  />
                </a>
              </div>
              <div v-show="showAttribute">
                <ProductAttributeGrid v-model="row.ProductAttributeValues" />
              </div>
            </div>
            <div
              v-if="row && row.Description"
              class="post intro-y overflow-hidden box mx-3 mt-2 border p-3"
            >
              <div class="flex items-center ml-1 mb-3">
                <a
                  href="#"
                  @click.prevent="showDescription = !showDescription"
                >說明</a>
                <a
                  href="#"
                  @click.prevent="showDescription = !showDescription"
                >
                  <FontAwesome
                    class="ml-2 h-4"
                    :icon="showDescription ? 'angle-up' : 'angle-down'"
                  />
                </a>
              </div>
              <div v-show="showDescription">
                <CKEditor
                  v-model="row.Description.Content"
                  v-bind="editorOptions"
                />
              </div>
            </div>
            <div
              v-if="row && row.PictureAlbum"
              class="post intro-y overflow-hidden box mx-3 mt-5 border p-3"
            >
              <div class="flex items-center ml-1 mb-3">
                <a
                  href="#"
                  @click.prevent="showAlbum = !showAlbum"
                >相簿</a>
                <a
                  href="#"
                  @click.prevent="showAlbum = !showAlbum"
                >
                  <FontAwesome
                    class="ml-2 h-4"
                    :icon="showAlbum ? 'angle-up' : 'angle-down'"
                  />
                </a>
              </div>
              <div v-show="showAlbum">
                <SliderEditor
                  ref="sliderEditor"
                  :navigation="true"
                  :width="sliderWidth"
                  :filePostAction="uploadAction"
                  :formOptions="pictureFormOptions"
                  v-model="row.PictureAlbum.Pictures"
                  @add="onSliderAdd"
                  @edit="onSliderEdit"
                  @save="onSliderSave"
                  @remove="onSliderRemove"
                  :itemHeight="1000"
                />
              </div>
            </div>
          </template>
          <template #modal-footer>
            <vxe-button
              type="submit"
              status="primary"
              content="確認"
              @click="$refs.form.validate((errMap) => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })"
            ></vxe-button>
            <vxe-button
              type="reset"
              content="重置"
              @click="$refs.form.dispatchEvent('reset')"
            ></vxe-button>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<style scoped>
.swiper-container {
  padding-top: 5px;
  padding-bottom: 30px;
}
</style>
<style>
textarea {
  min-height: 140px;
}

.vxe-select-option {
  max-width: 100% !important;
}
</style>

<script lang="ts">
import CloudFun, { Condition, defineComponent, Operator, ref, onMounted } from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'
import ProductAttributeGrid from '@/cloudfun/components/ProductAttributeGrid.vue'
import { VueUploadItem } from "vue-upload-component";
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import SliderEditor from '@/cloudfun/components/SliderEditor.vue'
import formatDate from 'xe-utils/toDateString'

export default defineComponent({
  components: {
    Grid,
    FileUploader,
    SelectBox,
    ProductAttributeGrid,
    SliderEditor,
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const uploader = ref<any>({});
    const selectedBrand = ref("");
    const selectedPiece = ref("");
    const country = ref([]);

    var printColumns = [
      { field: "Name" },
      { field: "Author" },
      { field: "Price" },
      { field: "MemberPrice" },
      { field: "PublishedString" },
      { field: "Ordinal" },
    ];

    const gridOptions: GridOptions = {
      title: "產品資料",
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      printConfig: {
        sheetName: "產品清單",
        columns: printColumns,
        modes: ["current", "selected", "all"],
      },
      exportConfig: {
        filename: "產品清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns,
      },
      columns: [
        {
          field: 'CreatedTime', title: '建立日期', showHeaderOverflow: true, showOverflow: true, sortable: true, width: "120", fixed: "left",
          formatter: ({ cellValue }) => cellValue ? formatDate(new Date(cellValue), 'yyyy/MM/dd') : ""
        },
        { field: "Brand.Name", title: "品牌", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Number", title: "編號", showHeaderOverflow: true, showOverflow: true, sortable: true, width: "100", fixed: "left" },
        { field: "Barcode", title: "條碼", showHeaderOverflow: true, showOverflow: true, sortable: true, width: "130", fixed: "left" },
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true, width: "170", fixed: "left" },
        { field: "Category.Name", title: "類別", showHeaderOverflow: true, showOverflow: true, sortable: true },
        // { field: "Country.Name", title: "國家", showHeaderOverflow: true, showOverflow: true, sortable: true },
        // { field: 'Ordinal', title: '排序', showHeaderOverflow: true, showOverflow: true, align: "right", width: 100, sortable: true },
        {
          field: "Undiscountable", title: "不允許折扣", showHeaderOverflow: true, showOverflow: true, align: "center", width: 100, sortable: true, resizable: false,
          cellRender: { name: "$switch", props: { openLabel: '是', closeLabel: '否', disabled: true } }
        },
        {
          field: "Published", title: "已發佈", showHeaderOverflow: true, showOverflow: true, align: "center", width: 100, sortable: true, resizable: false,
          cellRender: { name: "$switch", props: { openLabel: '是', closeLabel: '否', disabled: true } }
        },
      ],
      promises: {
        query: model ? async (params) => {
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          if (selectedBrand.value !== "") {
            params.condition!.and("BrandId", Operator.Equal, selectedBrand.value?.toString());
          }
          if (selectedPiece.value !== "") {
            params.condition!.and("ProductAttributeValues.AttributeValue.DecimalValue", Operator.Equal, selectedPiece.value);
          }
          return await model.dispatch("product/query", params);
        } : undefined,
        queryAll: model ? (params) => model.dispatch("product/query", params) : undefined,
        save: model ? (params) => model.dispatch("product/save", params) : undefined,
      },
      modalConfig: { width: "60%", height: "80%", showFooter: true },
    };
    const formOptions = {
      titleWidth: 110,
      titleAlign: "right",
      items: [
        { field: "Name", title: "名稱", span: 12, itemRender: { name: "$input", props: { placeholder: "請輸入文字" } } },
        { field: "CategoryId", title: "類別", span: 12, slots: { default: "column-category-id" } },
        { field: "Number", title: "編號", span: 12, itemRender: { name: "$input", props: { placeholder: "未輸入時由系統產生" } } },
        { field: "BrandId", title: "品牌", span: 12, slots: { default: "column-brand-id" } },
        { field: "Barcode", title: "條碼", span: 12, itemRender: { name: "$input", props: { placeholder: "請輸入條碼" } } },
        { field: "CountryId", title: "國家", span: 12, slots: { default: "column-country-id" } },
        { field: "PurchasePrice", title: "產品定價", span: 12, itemRender: { name: "$input", props: { type: "number", placeholder: "請輸入價格" } } },
        { field: "SalePice", title: "產品售價", span: 12, itemRender: { name: "$input", props: { type: "number", placeholder: "請輸入價格" } } },
        { field: "Undiscountable", title: "不允許折扣", span: 12, itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] } },
        { field: 'Ordinal', title: '排序', span: 12, itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' } } },
        { field: "Published", title: "已發佈", span: 12, itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] } },
        { field: "StartDate", title: "開始日期", span: 12, itemRender: { name: "$input", props: { type: "date", placeholder: "請輸入日期" } } },
        { field: "EndDate", title: "結束日期", span: 12, itemRender: { name: "$input", props: { type: "date", placeholder: "請輸入日期" } } },
      ],
      rules: {
        Name: [{ required: true }],
        Barcode: [{ required: true }],
        CategoryId: [{ required: true }],
        BrandId: [{ required: true }],
        CountryId: [{ required: true }],
        PurchasePrice: [{ type: "number", required: true, message: "預定價(含稅)" }],
        SalePice: [{ type: "number", required: true, message: "建議零售價" }],
      },
    };

    const categorySelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: 'Id',
      placeholder: '選擇分類',
      textField: 'Name',
      valueField: 'Id',
      formatText: async (row: any) => {
        let entity = await model!.dispatch("category/find", row.Id); // eslint-disable-line
        let text = entity.Name
        while (entity.Parent) {
          entity = entity.Parent
          text = `${entity.Name}>${text}`
        }
        return text
      },
      columns: [
        { field: 'Name', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true, treeNode: true }
      ],
      promises: {
        find: (value) => model!.dispatch("category/find", value), // eslint-disable-line
        query: (params) => new Promise((resolve) => {
          params.sortings = params.sortings || [];
          params.sortings.push({ column: 'CreatedTime', order: 1 });
          params.condition = params.condition || new Condition();
          params.condition.and('ParentId', Operator.IsNull);
          model!.dispatch('category/query', params).then(rep => {
            rep.data.forEach((item: any) => {
              item.Children = item.Children.sort((a: any, b: any) => b.Id - a.Id);
            })
            resolve(rep);
          });
        })
      },
      treeConfig: { parent: 'ParentId', children: 'Children', expandAll: true, reserve: true }
    }

    const brandSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: 'Id',
      placeholder: '選擇品牌',
      textField: 'Name',
      valueField: 'Id',
      formatText: async (row: any) => {
        let entity = await model!.dispatch("brand/find", row.Id); // eslint-disable-line
        let text = entity.Name
        while (entity.Parent) {
          entity = entity.Parent
          text = `${entity.Name}>${text}`
        }
        return text
      },
      columns: [
        { field: 'Name', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true, treeNode: true }
      ],
      promises: {
        find: (value) => model!.dispatch("brand/find", value), // eslint-disable-line
        query: (params) => model!.dispatch("brand/query", params) // eslint-disable-line
      }
    }

    const countrySelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: 'Id',
      placeholder: '選擇國家',
      textField: 'Name',
      valueField: 'Id',
      formatText: async (row: any) => {
        let entity = await model!.dispatch("country/find", row.Id); // eslint-disable-line
        let text = entity.Name
        while (entity.Parent) {
          entity = entity.Parent
          text = `${entity.Name}>${text}`
        }
        return text
      },
      columns: [
        { field: 'Name', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true, treeNode: true }
      ],
      promises: {
        find: (value) => model!.dispatch("country/find", value), // eslint-disable-line
        query: (params) => model!.dispatch("country/query", params) // eslint-disable-line
      },
    }

    const tagsSelectOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇標籤",
      textField: "Name",
      valueField: "Id",
      columns: [{ field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true }],
      multiselect: true,
      showHeader: true,
      addIfNotExists: true,
      promises: {
        find: (value) => model!.dispatch("tag/find", value), // eslint-disable-line
        query: (params) => model!.dispatch("tag/query", params), // eslint-disable-line
        insert: (row) => model!.dispatch("tag/insert", row), // eslint-disable-line
      }
    }

    const editorOptions = {
      editor: ClassicEditor,
      config: {
        extraPlugins: [UploadAdapterPlugin],
        toolbar: {
          shouldNotGroupWhenFull: true,
          items: [
            "heading",
            "|",
            "fontFamily",
            "fontSize",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "bold",
            "italic",
            "underline",
            "alignment",
            "bulletedList",
            "numberedList",
            "outdent",
            "indent",
            "highlight",
            "insertTable",
            "|",
            "link",
            "blockQuote",
            "imageInsert",
            "mediaEmbed",
            // 'codeBlock',
            "htmlEmbed",
            "|",
            "|",
            "undo",
            "redo",
            "sourceEditing"
          ]
        },
        removePlugins: ["Markdown"],
        image: {
          toolbar: [
            "imageTextAlternative",
            "imageStyle:full",
            "imageStyle:side",
            "linkImage"
          ]
        },
        table: {
          contentToolbar: [
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "tableCellProperties",
            "tableProperties"
          ]
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph"
            },
            {
              model: "heading1",
              view: { name: "h1", classes: "font-bold text-xl" },
              title: "Heading 1",
              class: "ck-heading_heading1"
            },
            {
              model: "heading2",
              view: { name: "h2", classes: "font-bold text-base" },
              title: "Heading 2",
              class: "ck-heading_heading2"
            }
          ]
        }
      }
    }

    const pictureFormOptions = {
      titleWidth: 40,
      titleAlign: 'right',
      items: [
        {
          field: 'Name',
          title: '名稱',
          span: 14,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true } }
        }, {
          field: 'Ordinal',
          title: '順序',
          span: 10,
          titleWidth: 40,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' } }
        }, {
          field: 'Remark',
          title: '備註',
          span: 24,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true } }
        },
      ],
      rules: {
        Name: [{ type: 'string', max: 128 }]
      }
    }

    const getCountry = async () => {
      var sortings = [{ column: "Ordinal", order: 0 }];
      return model?.dispatch("brand/query", { sortings }).then((data) => {
        data = data.filter((item: any) => {
          return item.Name !== null
        })
          .sort((a: any, b: any) => {
            const nameA = a.Name.toUpperCase();
            const nameB = b.Name.toUpperCase();
            if (nameA < nameB) {
              return -1;
            } else if (nameA > nameB) {
              return 1
            } else {
              return 0
            }
          })
        return data;
      }).catch((error) => {
        console.log(error)
      })
    }

    onMounted(async () => {
      country.value = await getCountry();
    })

    return {
      grid,
      uploader,
      gridOptions,
      formOptions,
      categorySelectOptions,
      brandSelectOptions,
      countrySelectOptions,
      tagsSelectOptions,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/Files`,
      editorOptions,
      sliderWidth: ref('100%'),
      pictureFormOptions,
      showAttribute: ref(true),
      showAlbum: ref(true),
      showDescription: ref(true),
      selectedBrand,
      country,
      selectedPiece,
    };
  },
  methods: {
    onGridAdd(row: any, callback: any) {
      row.Undiscountable = false;
      row.Published = false;
      row.Ordinal = 0;
      row.Description = {
        Content: `<p>*本店販售的皆為國內外全新正品，請安心選購。</p><p>*拼圖拼片因不同品牌而有所差異,例如:拼片大小、厚度、印刷、刀模、密合度…等,而且紙質拼圖製程中多少會有紙屑產生,甚至少數拼片可能會有輕微翹起或未完全切割(連片)少數缺片…等情形,以上皆屬正常情形而不算瑕疵，完美主義者請勿下單。</p><p>*電腦照片呈現難免有些微色差，商品尺寸及顏色以實際商品為主，無法接受請勿下單，下單前也請再次確認選購的拼圖款式，如收到商品後有問題，商品須保持全新，並於取貨後7天內與本店連絡。若經拆封、使用後導致商品不完整的話，恕不提供退換貨服務。</p>`
      };
      row.PictureAlbum = {
        Pictures: []
      };
      callback();
    },
    async onGridEdit(row: any, callback: any) {
      const entity = row.Id
        ? await this.$model.dispatch("product/find", row.Id)
        : undefined;

      if (entity) Object.assign(row, entity);
      callback();
    },
    onGridRemoveSelectedRows(rows: any, callback: any) {
      cash("#batch-dropdown").dropdown("hide");
      callback();
    },
    async uploaderFilter(current: VueUploadItem, original: VueUploadItem, prevent: any) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    },
    async onSliderAdd(image: any, callback: any) {
      try {
        if (!this.grid.editingRow.Id) {
          // if (!confirm("新增圖片集須先建立此筆產品，是否同意?")) return;

          const entity = await this.$model.dispatch('product/insert', this.grid.editingRow)
          const product = await this.$model.dispatch("product/find", entity.Id)
          Object.assign(this.grid.editingRow, product);
          this.grid.refresh();
        }
        image.AlbumId = this.grid.editingRow.PictureAlbumId;
        callback();
      } catch (failure: any) {
        this.$send("error", failure)
      }
    },
    onSliderEdit(image: any, callback: any) {
      image.AlbumId = this.grid.editingRow.PictureAlbumId;
      callback();
    },
    async onSliderRemove(image: any, callback: any) {
      await this.$model.dispatch('picture/delete', image.Id);
      callback();
    },
    async onSliderSave(params: any, callback: any) {
      await this.$model.dispatch('picture/save', params).then(
        () => {
          this.$model.dispatch('pictureAlbum/find', this.grid.editingRow.PictureAlbumId).then(
            (entity) => {
              entity.Pictures.forEach((picture: any) => {
                delete picture.$id;
                delete picture.Album;
              });
              const sliderEditor: any = this.$refs.sliderEditor;
              sliderEditor.reload(entity.Pictures.sort((a: any, b: any) => a.Ordinal - b.Ordinal));
            },
            reason => { CloudFun.send('error', { subject: '重載失敗', content: reason }) }
          )
        },
        reason => { CloudFun.send('error', { subject: '保存失敗', content: reason }) }
      );
      callback();
    },
    async onFormBrandChanged(row: any, value: any) {
      if (!value) return;
      const brand = await this.$model.dispatch("brand/find", value);
      row.CountryId = brand.CountryId;
    }
  },
});
</script>
