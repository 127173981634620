
import { defineComponent, PropType, ref } from "@cloudfun/core";
import { VxeTablePropTypes } from "vxe-table";

export default defineComponent({
  props: {
    modelValue: Array as PropType<any[]>
  },
  setup() {
    const attributeGrid = ref({});
    const groupValues = ref<any>([]);
    const values = ref<any>([]);
    const rowStyle: VxeTablePropTypes.RowStyle = ({ row }) => {
      if (row.Attribute.Required) {
        return {
          backgroundColor: '#F000003F',
        }
      }
    };

    return {
      attributeGrid,
      rowStyle,
      groupValues,
      values,
    };
  },
  watch: {
    modelValue(current: any) {
      if (current !== this.values) {
        if (Array.isArray(this.modelValue)) {
          for (const item of this.modelValue) {
            if (item.AttributeValue) {
              const group = this.groupValues.find((e: any) => e.Attribute.Id === item.AttributeValue.AttributeId);
              if (group) {
                group.AttributeValues = [];
                group.SelectedValues = group.Multiple ? [] : undefined;
                group.AttributeValues.push(item.AttributeValue);
                if (group.Attribute.Selectable) {
                  if (group.Attribute.Multiple) group.SelectedValues.push(item.AttributeValueId);
                  else group.SelectedValues = item.AttributeValueId;
                }
              }
            }
          }
        }
        for (const group of this.groupValues) {
          if (!group.AttributeValues.length) {
            if (group.Attribute.Required) {
              if (group.Attribute.Selectable) {
                group.AttributeValues.push(group.Attribute.Values[0]);
                if (group.Multiple) group.SelectedValues.push(group.Attribute.Values[0].Id);
                else group.SelectedValues = group.Attribute.Values[0].Id;
              } else group.AttributeValues.push({ AttributeId: group.Attribute.Id });
            } else group.AttributeValues.push({ AttributeId: group.Attribute.Id });
          }
        }
        this.computeValues();
        this.$emit("update:modelValue", this.values);
      }
    }
  },
  mounted() {
    this.$model.dispatch("attribute/query").then(
      payload => {
        this.groupValues = payload.map((e: any) => {
          const values = e.Values;
          values.sort((a: any, b: any) => b.DecimalValue - a.DecimalValue);
          return {
            Attribute: {
              Id: e.Id,
              Name: e.Name,
              Code: e.Code,
              Ordinal: e.Ordinal,
              Required: e.Required,
              Selectable: e.Selectable,
              Values: values,
              Multiple: e.Multiple
            },
            AttributeValues: [],
            SelectedValues: e.Multiple ? [] : undefined,
          };
        });
        if (Array.isArray(this.modelValue)) {
          for (const item of this.modelValue) {
            if (item.AttributeValue) {
              const group = this.groupValues.find((e: any) => e.Attribute.Id === item.AttributeValue.AttributeId);
              if (group) {
                group.AttributeValues.push(item.AttributeValue);
                if (group.Attribute.Selectable) {
                  if (group.Attribute.Multiple) group.SelectedValues.push(item.AttributeValueId);
                  else group.SelectedValues = item.AttributeValueId;
                }
              }
            }
          }
        }
        for (const group of this.groupValues) {
          if (!group.AttributeValues.length) {
            if (group.Attribute.Required) {
              if (group.Attribute.Selectable) {
                group.AttributeValues.push(group.Attribute.Values[0]);
                if (group.Multiple) group.SelectedValues.push(group.Attribute.Values[0].Id);
                else group.SelectedValues = group.Attribute.Values[0].Id;
              } else group.AttributeValues.push({ AttributeId: group.Attribute.Id });
            } else group.AttributeValues.push({ AttributeId: group.Attribute.Id });
          }
        }
        this.computeValues();
        this.$emit("update:modelValue", this.values);
      },
      failure => this.$send("error", { subject: "屬性讀取失敗！", content: failure })
    )
  },
  methods: {
    computeValues() {
      this.values = [];
      for (const group of this.groupValues) {
        for (const value of group.AttributeValues) {
          this.values.push({
            AttributeValueId: value.Id,
            AttributeValue: value,
          })
        }
      }
    },
    onChange(attributeId: any) {
      const group = this.groupValues.find((e: any) => e.Attribute.Id === attributeId);
      if (group.Attribute.Selectable) {
        if (Array.isArray(group.SelectedValues)) {
          group.AttributeValues = group.SelectedValues.map((value: any) => {
            return {
              AttributeId: group.Attribute.Id,
              Id: value,
            }
          });
        } else {
          group.AttributeValues = [{
            AttributeId: group.Attribute.Id,
            Id: group.SelectedValues,
          }];
        }
      }
      this.computeValues();
      this.$emit("update:modelValue", this.values);
    }
  }
});
